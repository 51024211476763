import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createOEM = async (data) => {
  const response = await axios.post(API_URL + "api/oem/register", { ...data }, { headers: authHeader() });
  console.log(response);
  return response.data;
};

const getAllOEM = async () => {
  const response = await axios.get(API_URL + "api/oem", { headers: authHeader() });
  return response.data;
};

const getOEMbyid = async (oem_id) => {
  const response = await axios.get(API_URL + `api/oem/${oem_id}`, { headers: authHeader() });
  return response.data;
};

const updateOEM = async (oem_id, formData) => {
  const response = await axios.put(API_URL + `api/oem/${oem_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteOEM = async (oem_id) => {
  const response = await axios.delete(API_URL + `api/oem/${oem_id}`, { headers: authHeader() });
  return response.data;
};

const oemService = {
  createOEM,
  getAllOEM,
  getOEMbyid,
  updateOEM,
  deleteOEM
};

export default oemService