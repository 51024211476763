import React, { useEffect , useState} from 'react';
import { Box, Grid, Button, Typography, TextField, Divider, Modal } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { getalloem } from '../../slices/oem';
import Popup from '../../components/popup/Popup';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};

const columns = [
    { id: 'oem_id', label: 'OEM ID', minWidth: 150, align:'start' },
    { id: 'organization', label: 'Organization', minWidth: 150, align:'start' },
    { id: 'email', label: 'Email ID', minWidth: 150, align:'center' },
    { id: 'location', label: 'Location', minWidth: 150, align:'center' },
    { id: 'gst_no', label: 'GST NO', minWidth: 150, align:'center' },
    { id: 'status', label: 'Status', minWidth: 150, align:'center' },
    { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{view: true, edit: true, delete: true}] },
  ];

const Oem = ({setLoader, show}) => {

  const [open, setOpen] = React.useState(false);
  const [modalte, setModalte] = React.useState(null)

  const {oems: oemData} = useSelector((state) => state.oem) || [];

  const {message: message} = useSelector((state) => state.oem) || [];

  const dispatch = useDispatch();

  useEffect(()=>{
    if(message){
          setOpen(true);
          setModalte(message);
    }
  },[message]);

  useEffect(()=>{
    setLoader(true)
      dispatch(getalloem())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
    <Popup open={open} modalte={modalte} setOpen={setOpen}/>
      <Box>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
            My OEMs
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton placeholder="Search OEMs"/>
          <Link to='/new'>
          <Button variant='contained' size='large'>+ Add OEM</Button>
          </Link>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable columns={columns} rows={oemData ? oemData : []} setLoader={setLoader} />
      </Box>
      </Box>
    </div>
  );
}

export default Oem;