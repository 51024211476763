import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function CustSlider() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
      {Array.from(Array(6)).map((_, index) => (
        <div>
        <Card sx={{ maxWidth: '90%', boxShadow:'none', bgcolor:'light.light' }}>
      <CardMedia
        sx={{ height: 200 }}
        image="https://www.ohoh.in/wp-content/uploads/2023/12/Best-Dealer-management-in-india-Inventory-Management-for-Dealerships-Blog2.webp"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
        Where modern problems meet cutting-edge solutions.
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Digital technologies have transformed customer engagement in the automotive sector, with online platforms and mobile apps reshaping the entire car-buying process Reporting & Dashboard Provides customizable reports on sales performance,
        </Typography>
      </CardContent>
      <CardActions sx={{px: 2}}>
        <Button size="small" variant="outlined">Read More</Button>
      </CardActions>
    </Card>
        </div>
        ))}
      </Slider>
    </div>
  );
}

export default CustSlider;
