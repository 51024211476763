import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const Dashboard = ({show}) => {

  const { user: currentUser } = useSelector((state) => state.auth);

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>Dashboard</div>
  )
}

export default Dashboard