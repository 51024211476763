import React, { useEffect , useState} from 'react';
import { Box, Grid, Button, Typography, TextField, Divider, Autocomplete } from '@mui/material';
import CustomTable from '../../components/table/Table2';
import Adddealer from './Adddealer';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { getdealer, getdealersbyoemid } from '../../slices/dealer';
import { getalloem } from '../../slices/oem';

const columns = [
    { id: 'dealer_id', label: 'Dealer ID', minWidth: 150, align:'start' },
    { id: 'oem_id', label: 'OEM ID', minWidth: 150, align:'start' },
    { id: 'first_name', label: 'Dealer Name', minWidth: 150, align:'start' },
    { id: 'email', label: 'Email ID', minWidth: 150, align:'center' },
    { id: 'zone', label: 'Location', minWidth: 150, align:'center' },
    { id: 'gst_no', label: 'GST NO', minWidth: 150, align:'center' },
    { id: 'status', label: 'Status', minWidth: 150, align:'center' },
    // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: true}] },
  ];

const Dealer = ({setLoader, show}) => {

  const [oems, setOems] = useState([]);

  const [oemvalue, setOemvalue] = useState("")

  const {dealers: dealer} = useSelector((state) => state.dealer);

  const {oems: oem} = useSelector((state) => state.oem);

  useEffect(()=>{
      setOems(oem.map((a) => a?.oem_id));
  }, [oem])

  const dealerData = dealer?.map((va)=>{
    return{
      ...va,
      bank_details: JSON.parse(va.bank_details),
      billing_address: JSON.parse(va.billing_address),
      shipping_address: JSON.parse(va.shipping_address),
    }
  });

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getdealer())
      dispatch(getalloem())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const handleOEMchange = (e, value) =>{
    setOemvalue(value);
    setLoader(true)
    dispatch(getdealersbyoemid(value))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={8} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
            My Dealers
            </Typography>
          </Grid>
          <Grid item xs={2}>
          <Autocomplete fullWidth size="small"
  disablePortal
  id="combo-box-demo"
  options={oems || []}
  value={oemvalue}
                onChange={(e, value) => handleOEMchange(e, value)}
  renderInput={(params) => <TextField placeholder="Select OEM" {...params}  />}
/>
          </Grid>
          <Grid item xs={2} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton placeholder="Search Dealer"/>
          {/* <Link to='/dealer/new'>
          <Button variant='contained' size='large'>+ Add Dealer</Button>
          </Link> */}
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable columns={columns} rows={dealerData ? dealerData : []} setLoader={setLoader}/>
      </Box>
      </Box>
    </div>
  );
}

export default Dealer;