import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const user = JSON.parse(localStorage.getItem('user'));

const createProduct = async (data) => {
  const response = await axios.post(API_URL + "api/vehicle", { ...data, "user_id": user.user_id }, { headers: authHeader() });
  console.log(response);
  return response.data;
};

const createVariant = async (data) => {
  const response = await axios.post(API_URL + "api/variants", { ...data}, { headers: authHeader() });
  console.log(response);
  return response.data;
};

const createSpare = async (data) => {
  const response = await axios.post(API_URL + "api/spares", { ...data, "user_id": user.user_id}, { headers: authHeader() });
  console.log(response);
  return response.data;
};

const createAccessory = async (data) => {
  const response = await axios.post(API_URL + "api/accessories", { ...data, "user_id": user.user_id}, { headers: authHeader() });
  console.log(response);
  return response.data;
};

const getProduct = async () => {
  const response = await axios.get(API_URL + `api/vehicle/${user.user_id}`, { headers: authHeader() });
  return response.data;
};

const getSpares = async () => {
  const response = await axios.get(API_URL + `api/spares/${user.user_id}`, { headers: authHeader() });
  return response.data;
};

const getAccessories = async () => {
  const response = await axios.get(API_URL + `api/accessories/${user.user_id}`, { headers: authHeader() });
  return response.data;
};

const productService = {
  createProduct,
  createVariant,
  getProduct,
  createSpare,
  createAccessory,
  getSpares,
  getAccessories
};

export default productService