import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import VariantTabs from "./Variant";
import { createproduct } from "../../slices/product";


const AddProduct = ({ setLoader, show }) => {

  const [variantsubmit, setVariantSubmit] = useState(false);

  const [vehicleid, setVehicleid] = useState(1);

  const [vehicledata, setVehicledata] = useState({
    product_type:"Vehicle",
    product_name:"",
    product_img:"url",
    hsn_no:0,
    mft_year:0,
    cgst:0,
    sgst:0,
    igst:0
  })

  useEffect(()=>{
    if(vehicledata.product_type === "Spare"){
      navigate('/product/addspare')
    }
    else if(vehicledata.product_type === "Accessory"){
      navigate('/product/addaccessory')
    }
  }, [vehicledata.product_type])


  const dispatch = useDispatch();

  const navigate = useNavigate();


  const arrowBack = () => {
    navigate('/product');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true)

    dispatch(createproduct({...vehicledata}))
    .then((data) => {
      setVariantSubmit(true);
      setVehicleid(data.payload.productdata.data.id);
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5">New Product</Typography>
          </Grid>
          <Grid item xs={8} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={3}>
                <Typography >Product Type *</Typography>
                </Grid>
                <Grid item xs={9}>
                <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={vehicledata.product_type}
                onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        product_type: e.target.value,
                      })
                    }
                >
                  <FormControlLabel
                    value="Vehicle"
                    control={<Radio />}
                    label="Vehicle"
                  />
                  <FormControlLabel
                    value="Spare"
                    control={<Radio />}
                    label="Spare"
                  />
                    <FormControlLabel
                    value="Accessory"
                    control={<Radio />}
                    label="Accessory"
                  />
                </RadioGroup>
              </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography >Product Name *</Typography>
                </Grid>
                <Grid item xs={9}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter Product Name"
                variant="outlined"
                value={vehicledata.product_name}
                onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        product_name: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={3}>
                <Typography>Type *</Typography>
                </Grid>
                <Grid item xs={3}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicledata.product_type}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        product_type: e.target.value,
                      })
                    }        >
            <MenuItem value="2 Wheeler">2 Wheeler</MenuItem>
            <MenuItem value="3 Wheeler">3 Wheeler</MenuItem>
            <MenuItem value="24 Wheeler">24 Wheeler</MenuItem>
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography>Registration *</Typography>
                </Grid>
                <Grid item xs={3}>
                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value="No"
          // onChange={(e) =>
          //             setVehicledata({
          //               ...vehicledata,
          //               product_type: e.target.value,
          //             })
          //           }  
        >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography >HSN No *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter HSN No"
                variant="outlined"
                value={vehicledata.hsn_no}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        hsn_no: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >MFT. Year </Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.mft_year}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        mft_year: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >CGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.cgst}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        cgst: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >SGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.sgst}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        sgst: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >IGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.igst}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        igst: e.target.value,
                      })
                    }   
              />
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
          <Typography variant="h6">Upload Product Image</Typography>
          <CloudUpload/>
          <Typography color='light' sx={{my: 2}}>Preferred Image Dimensions: 240 x 240 pixels @ 72 DPI Maximum File Size: 1MB</Typography>
          </Grid>
        </Grid>
        <VariantTabs variantsubmit={variantsubmit} setVariantSubmit={setVariantSubmit} setLoader={setLoader} vehicleid={vehicleid}/>
        <Divider orientation="horizontal" sx={{mb: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Create Product</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default AddProduct;
