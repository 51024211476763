import React, { useEffect, useState } from 'react';
import './Sidebar.css'
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {Home} from '@mui/icons-material';
import classNames from 'classnames';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const Sidebar = ({show, setShow}) => {

const [selectmenu, setSelectmenu] = useState('')

const side_menus = [
        // {
        // menuName: 'Dashboard',
        // icon: HomeOutlinedIcon,
        // route: '/'
        // },
        {
            menuName: 'OEM',
            icon: PeopleAltOutlinedIcon,
            route: '/'
        },
        {
            menuName: 'Dealers',
            icon: PeopleAltOutlinedIcon,
            route: '/dealer'
        },
        // {
        //   menuName: 'Inventory',
        //   icon: Inventory2OutlinedIcon,
        //   route: '/product'
        //   },
        //   {
        //       menuName: 'Purchases',
        //       icon: InventoryOutlinedIcon,
        //       route: '/inventory'
        //   },
        //   {
        //     menuName: 'Delivery Challan',
        //     icon: ArticleOutlinedIcon,
        //     route: '/deliverychallan'
        //     },
        //     {
        //         menuName: 'User Management',
        //         icon: SettingsOutlinedIcon,
        //         route: '/usermanagement'
        //     },
        //     {
        //       menuName: 'Report',
        //       icon: AssessmentOutlinedIcon,
        //       route: '/report'
        //   },
]

const handleMenu = (val) =>{
    setSelectmenu(val);
}

useEffect(()=>{
   const val = window.location.pathname;
   for(let i=0; i<side_menus.length; i++){
    if(val === side_menus[i].route){
        setSelectmenu(side_menus[i].menuName)
    }
   }
}, [])

  return (
    <div className='sidebar' style={{left : show ? 0 : '-250px'}}>
        <Box>
            <div>
                {
                    side_menus.map((val, index)=>{
                        return (
                          <Link key={index} to={val.route} onClick={()=> handleMenu(val.menuName)} style={{textDecoration:'none'}}>
                            <Box className={classNames("sidemenu", `${selectmenu === val.menuName && "active"}`)} sx={{ py: 2, px: 2 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <val.icon sx={{ marginRight: 1, color: "#000" }} />
                                <Typography sx={{ color: "#000", fontSize: 14 }}>
                                  {val.menuName}
                                </Typography>
                              </div>
                            </Box>
                          </Link>
                        );
                    })
                }
                {/* <Link to={'/'}>
                <Box className='sidemenu' sx={{py: 2, px: 3}}>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <Home sx={{marginRight: 1, color:'#fff'}}/>
                    <Typography sx={{color:'#fff'}}>Home</Typography> 
                    </div>
                </Box>
                </Link>
                <Link to={'/menu'}>
                <Box className='sidemenu' sx={{py: 2, px: 3}}>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <Home sx={{marginRight: 1, color:'#fff'}}/>
                    <Typography sx={{color:'#fff'}}>Menu</Typography> 
                    </div>
                </Box>
                </Link> */}
            </div>
        </Box>
    </div>
  )
}

export default Sidebar