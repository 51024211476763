import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import productService from "../services/product.service";

export const createproduct = createAsyncThunk(
  "api/createproduct",
  async (stateData, thunkAPI) => {
    try {
      const data = await productService.createProduct(stateData);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createvariant = createAsyncThunk(
  "api/createvariant",
  async (stateData, thunkAPI) => {
    try {
      const data = await productService.createVariant(stateData);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createspare = createAsyncThunk(
  "api/createspare",
  async (stateData, thunkAPI) => {
    try {
      const data = await productService.createSpare(stateData);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createaccessory = createAsyncThunk(
  "api/createaccessory",
  async (stateData, thunkAPI) => {
    try {
      const data = await productService.createAccessory(stateData);
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getproduct = createAsyncThunk(
  "api/getproduct",
  async (thunkAPI) => {
    try {
      const data = await productService.getProduct();
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getspare = createAsyncThunk(
  "api/getspare",
  async (thunkAPI) => {
    try {
      const data = await productService.getSpares();
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getaccessory = createAsyncThunk(
  "api/getaccessory",
  async (thunkAPI) => {
    try {
      const data = await productService.getAccessories();
      return { productdata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    vehicles: [],
    spares:[],
    accessories:[]
}

const productSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createproduct.fulfilled]: (state, action) => {
        console.log("vasanth", action.payload.productdata.data)
      state.vehicles.push(action.payload.productdata.data)
      return state;
    },
    [createproduct.rejected]: (state, action) => {
      state.vehicles = action.payload.productdata
      return state;
    },
    [createvariant.fulfilled]: (state, action) => {
      console.log("vasanth", action.payload.productdata.data)
    return state;
  },
[createvariant.rejected]: (state, action) => {
  return state;
},
[createspare.fulfilled]: (state, action) => {
  console.log("vasanth", action.payload.productdata.data)
state.spares.push(action.payload.productdata.data)
return state;
},
[createaccessory.rejected]: (state, action) => {
state.accessories = action.payload.productdata
return state;
},
[createaccessory.fulfilled]: (state, action) => {
state.accessories.push(action.payload.productdata.data)
return state;
},
[createspare.rejected]: (state, action) => {
state.spares = action.payload.productdata
return state;
},
    [getproduct.fulfilled]: (state, action) => {
    state.vehicles = action.payload.productdata.data
    return state;
  },
  [getproduct.rejected]: (state, action) => {
    state.vehicles = action.payload.productdata
    return state;
  },
  [getspare.fulfilled]: (state, action) => {
    state.spares = action.payload.productdata.data
    return state;
  },
  [getspare.rejected]: (state, action) => {
    state.spares = action.payload.productdata
    return state;
  },
  [getaccessory.fulfilled]: (state, action) => {
    state.accessories = action.payload.productdata.data
    return state;
  },
  [getaccessory.rejected]: (state, action) => {
    state.accessories = action.payload.productdata
    return state;
  }
  },
});

const { reducer } = productSlice;
export default reducer;