import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const user = JSON.parse(localStorage.getItem('user'));

const createNewChallan = async (data) => {
  const response = await axios.post(API_URL + "api/delivery-challan", { ...data, "user_id":user.user_id }, { headers: authHeader() });
  console.log(response);
  return response.data;
};

const getChallan = async () => {
  const response = await axios.get(API_URL + `api/delivery-challan/${user.user_id}`, { headers: authHeader() });
  return response.data;
};

const challanService = {
  createNewChallan,
  getChallan
};

export default challanService