import React, {useState} from 'react';
import Dashboard from './pages/dashboard/Dashboard.js';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Login from './pages/login/Login.js';
import Dealer from './pages/dealer/Dealer.js';
import Orders from './pages/orders/Orders.js';
import Products from './pages/products/Products.js';
import Inventory from './pages/inventory/Inventory.js';
import Warranty from './pages/warranty/Warranty.js';
import Challan from './pages/deliverychallan/Challan.js';
import Report from './pages/report/Report.js';
import Setting from './pages/settings/Setting.js';
import Layout2 from './Layout2.js';
import Home from './pages/home/Home.js';
import Integration from './pages/integration/Integration.js';
import Aboutus from './pages/aboutus/Aboutus.js';
import Contact from './pages/Contact/Contact.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import AddDealer from './pages/dealer/Adddealer.js';
import Createuser from './pages/usermanagement/Createuser.js';
import AddProduct from './pages/products/Addproduct.js';
import AddSpare from './pages/products/Addspare.js';
import AddAccessory from './pages/products/Addaccessory.js';
import AddProductStock from './pages/inventory/Addproductstock.js';
import AddSpareStock from './pages/inventory/Addsparestock.js';
import AddAccessoryStock from './pages/inventory/Addaccessorystock.js';
import AddChallan from './pages/deliverychallan/Addchallan.js';
import Usermanagement from './pages/usermanagement/Usermanagement.js';
import Oem from './pages/oem/Oem.js';
import AddOem from './pages/oem/Addoem.js';
import UpdateOem from './pages/oem/Updateoem.js';


const RouteRoute = ({setLoader}) => {

  const [show, setShow] = useState(true);

  return (
    <>
    <Routes>
        <Route path='/login' element={<Login setLoader={setLoader}/>}></Route> 
        <Route path='/' element={<Layout show={show} setShow={setShow}/>}>
        {/* <Route path='/' element={<ProtectedRoute><Dashboard show={show} setLoader={setLoader}/></ProtectedRoute>}></Route> */}
        <Route path='/'>
              <Route index={true} element={<ProtectedRoute><Oem show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='new' element={<ProtectedRoute><AddOem show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='update/:oemId' element={<ProtectedRoute><UpdateOem show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/dealer'>
              <Route index={true} element={<ProtectedRoute><Dealer show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              {/* <Route path='new' element={<ProtectedRoute><AddDealer show={show} setLoader={setLoader}/></ProtectedRoute>}></Route> */}
        </Route>       
         {/* <Route path='/product'>
              <Route index={true} element={<ProtectedRoute><Products show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addproduct' element={<ProtectedRoute><AddProduct show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addspare' element={<ProtectedRoute><AddSpare show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addaccessory' element={<ProtectedRoute><AddAccessory show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/inventory'>
              <Route index={true} element={<ProtectedRoute><Inventory show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addproductstock' element={<ProtectedRoute><AddProductStock show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addsparestock' element={<ProtectedRoute><AddSpareStock show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addaccessorystock' element={<ProtectedRoute><AddAccessoryStock show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/warranty' element={<ProtectedRoute><Warranty show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/deliverychallan'>
              <Route index={true} element={<ProtectedRoute><Challan show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='addchallan' element={<ProtectedRoute><AddChallan show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/usermanagement' >
            <Route index={true} element={<ProtectedRoute><Usermanagement show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
            <Route path='createuser' element={<ProtectedRoute><Createuser show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/report' element={<ProtectedRoute><Report show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/setting' >
            <Route index={true} element={<ProtectedRoute><Setting show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route> */}
      </Route>
    </Routes>
    </>
  )
}

export default RouteRoute;