import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography, Card } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import BasicTabs from '../../components/tabs/Tabs1';
import { Link } from 'react-router-dom';
import { getaccessory, getproduct, getspare} from '../../slices/product';
import { getaccessorystock, getproductstock, getsparestock } from '../../slices/inventory';

const Inventory = ({setLoader, show}) => {

  const [value, setValue] = React.useState(0);

  const {vehicles: vehicleStockData} = useSelector((state) => state.inventory) || [];

  const {spares: spareStockData} = useSelector((state) => state.inventory) || [];

  const {accessories: accessoriesStockData} = useSelector((state) => state.inventory) || [];

  const {vehicles: vehicleApiData} = useSelector((state) => state.product) || [];

  const {spares: spareApiData} = useSelector((state) => state.product) || [];

  const {accessories: accessoriesApiData} = useSelector((state) => state.product) || [];

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getproductstock())
      dispatch(getsparestock())
      dispatch(getaccessorystock())
      dispatch(getproduct())
      dispatch(getspare())
      dispatch(getaccessory())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5'>
             Inventory
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton placeholder="Search Model"/>
          { value === 0 &&
          <Link to='/inventory/addproductstock'>
          <Button variant='contained' size='large'>+ Add Stock</Button>
          </Link>
          }
          {
            value === 1 &&
            <Link to='/inventory/addsparestock'>
            <Button variant='contained' size='large'>+ Add Stock</Button>
          </Link>
          }
          {
            value === 2 &&
            <Link to='/inventory/addaccessorystock'>
            <Button variant='contained' size='large'>+ Add Stock</Button>
          </Link>
          }
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} sx={{mb: 2}}>
          <Grid item xs={3}>
            <Card sx={{boxShadow:'none', bgcolor:'#eaeaea', p: 3, border:'1px solid #cacaca'}}>
                <Typography variant='h6'>Vehicle Model</Typography>
                <Typography variant='p'>03</Typography>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{boxShadow:'none', bgcolor:'#eaeaea', p: 3, border:'1px solid #cacaca'}}>
                <Typography variant='h6'>Vehicle Stocks</Typography>
                <Typography variant='p'>443</Typography>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{boxShadow:'none', bgcolor:'#eaeaea', p: 3, border:'1px solid #cacaca'}}>
                <Typography variant='h6'>Spare Stocks</Typography>
                <Typography variant='p'>747</Typography>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{boxShadow:'none', bgcolor:'#eaeaea', p: 3, border:'1px solid #cacaca'}}>
                <Typography variant='h6'>Accessory Stocks</Typography>
                <Typography variant='p'>3363</Typography>
            </Card>
          </Grid>
      </Grid>
      <Box>
        <BasicTabs vehicleStockData={vehicleStockData} spareStockData={spareStockData} accessoriesStockData={accessoriesStockData} vehicleApiData={vehicleApiData} spareApiData={spareApiData} accessoriesApiData={accessoriesApiData} value={value} setValue={setValue}/>
      </Box>
    </div>
  );
}

export default Inventory;