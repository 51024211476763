import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import Adddealer from "./Addoem";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createdealer } from "../../slices/dealer";
import { getregion } from "../../slices/setting";
import { createoem, getoembyid, updateoem } from "../../slices/oem";


const states =
[
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal"
]

const UpdateOem = ({ setLoader, show }) => {

  const [file, setFile] = useState(null);

  const params = useParams();

  const [oemdata, setOemdata] = useState({
    role: "",
    user_role: "",
    first_name:"",
    last_name:"",
    organization: "", 
    gst_no:"",
    email:"",
    logo_url:"url",
    contact:"",
    password:"",
    country:"India",
    state:"",
    address:"",
    location:"",
    currency:"INR",
    tax:"",
    language:"EN",
    time_zone:"Asia/Kolkata"
  });

  useEffect(()=>{
    if(file){
      setOemdata({...oemdata, logo_url: file})
    }
  },[file])

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getoembyid(params.oemId))
      .then((data) => {
        setOemdata(data.payload.oemdata.data[0]);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handleSubmit = (e, oem_id) => {
    e.preventDefault();
    const formData = oemdata
    if(oem_id){
      setLoader(true)
      dispatch(updateoem({ oem_id, formData}))
      .then(() => {
        navigate(-1);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  };

  const handleDealerData = (e, name) =>{
    setOemdata({
      ...oemdata,
      [name]: e.target.value})
  }

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate  
    autoComplete="off"
    onSubmit={(e) => handleSubmit(e, oemdata.oem_id)}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>Update OEM</Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={4}>
                <Typography >Name *</Typography>
                </Grid>
                <Grid item xs={8}>
                <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1
                }}
              >
                <TextField
                   size="small"
                  fullWidth
                  id="outlined-basic"
                  placeholder="First Name"
                  variant="outlined"
                  value={oemdata.first_name}
                   onChange={(e) => handleDealerData(e, 'first_name')}
                />
                <TextField
                   size="small"
                  fullWidth
                  id="outlined-basic"
                  placeholder="Last Name"
                  variant="outlined"
                  value={oemdata.last_name}
                  onChange={(e) => handleDealerData(e, 'last_name')}
                />
              </Box>
                </Grid>
                <Grid item xs={4}>
                <Typography>Organization *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter Organization Name"
                variant="outlined"
                value={oemdata.organization}
                onChange={(e) => handleDealerData(e, 'organization')}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >GST No *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="GST988767567"
                variant="outlined"
                value={oemdata.gst_no}
                   onChange={(e) => handleDealerData(e, 'gst_no')}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Tax *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter tax percentage"
                variant="outlined"
                value={oemdata.tax}
                   onChange={(e) => handleDealerData(e, 'tax')}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Org Email ID *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="test@gmail.com"
                variant="outlined"
                value={oemdata.email}
                   onChange={(e) => handleDealerData(e, 'email')}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Org Contact *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter Contact Number"
                variant="outlined"
                value={oemdata.contact}
                   onChange={(e) => handleDealerData(e, 'contact')}
              />
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
          <Typography variant="h6">Organization Logo *</Typography>
          <CloudUpload setLoader={setLoader} setFile={setFile} file={oemdata.logo_url}/>
          <Typography sx={{my: 2}}>This logo will be displayed in transaction PDFs and email notifications.</Typography>
          <Typography color='light' sx={{my: 2}}>Preferred Image Dimensions: 240 x 240 pixels @ 72 DPI Maximum File Size: 1MB</Typography>
          </Grid>
        </Grid>
        <Grid container columnSpacing={5} sx={{mt: 2}}>
                  <Grid item xs={6}>
                  <Grid container spacing={2} sx={{ alignItems:'center'}}>
                  <Grid item xs={12}>
                <Typography variant="h6">Address</Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography >Country *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={oemdata.country}
                   onChange={(e) => handleDealerData(e, 'country')}
                InputProps={{
            readOnly: true,
          }}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >State *</Typography>
                </Grid>
                <Grid item xs={8}>
                <Autocomplete size="small"
  disablePortal
  id="combo-box-demo"
  options={states}
  value={oemdata.state}
                onChange={(e, value) =>
                      setOemdata({
                        ...oemdata,
                        state: value,
                      })
                    }
  renderInput={(params) => <TextField placeholder="Select State" {...params}  />}
/>
                </Grid>
                <Grid item xs={4}>
                <Typography >Location *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={oemdata.location}
                   onChange={(e) => handleDealerData(e, 'location')}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Address *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={oemdata.address}
                   onChange={(e) => handleDealerData(e, 'address')}
              />
                </Grid>
                  </Grid>
            </Grid>
            </Grid>
            <Divider sx={{my: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Update OEM</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default UpdateOem;
